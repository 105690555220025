/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import '../css/themify-icons/themify-icons.css';

:root {
  --wz-blue: #3069a7;
  --wz-red: #b13c37;
  --wz-typography: #8F92A1;
  --wz-form-text: #8F92A1;
  --wz-table-border: #EDF2F7;
}

* {
	outline: none
}

html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'DM Sans', sans-serif !important;
  background-color: #F9FAFA;
}
#content > div > div > div{
  resize: none !important;
}

.btn {
  box-shadow: none !important;
}

.modal-body {
  max-height: 600px !important;
}
.btn-primary {
  background-color: var(--wz-blue) !important;
  color: #ffffff !important;
  border-color: var(--wz-blue) !important;
}

.btn-rounded {
  border-radius: 50%;
}

.btn-danger {
  background-color: var(--wz-red) !important;
  color: #ffffff !important;
  border-color: var(--wz-red) !important;
}

.text-danger {
  color: var(--wz-red) !important;
}

.form-check-input {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--wz-blue);
  border-color: var(--wz-blue);
  box-shadow: var(--wz-blue);
}

.btn {
  height: 2.5rem !important;
  border-radius: 16px !important;
  padding: .375rem .875rem !important;
}

.btn.btn-small {
  height: 2.375rem !important;
  font-size: .75rem !important;
}
/* #main-wrapper > div > div > app-notification > div.row.justify-content-center > div > div > table > thead > tr > th:nth-child(2) */

table.dataTable thead th, table.dataTable thead td {
  padding: 10px 10px !important;
}

.table>:not(caption)>*>* {
  padding: 1.1rem .5rem;
  border-bottom-color: var(--wz-table-border);
}

.table > thead {
  background-color: #F5F5F7 !important;
  border-radius: 16px !important;
}

.table>:not(:last-child)>:last-child>* {
  border-bottom: none !important;
  /* border-bottom-color: var(--wz-table-border); */
}

table > thead > tr > th:nth-child(1) {
  padding-left: 20px !important;
}

table > tbody > tr > td:nth-child(1) {
  /* padding-left: 20px !important; */
}

.text-has-error {
  border: 1px solid darkred !important;
}
.error-text {
  color: darkred !important;
  font-size: 14px !important;
}


/* Table CSS */
.table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  padding: 0 10px;
}

.table > thead > th {
  width: 250px;
  padding: 10px 10px !important;
  white-space: nowrap;
  font-size: 14px;
  color: #8F92A1 !important;

}

.table td {
  width: 250px;
  padding: 18px 10px !important;
  white-space: nowrap;
  font-size: 14px;
  color: #8F92A1;

}

.table > tbody > tr {
  font-weight: 400;
  background-color: #ffffff;
  border-radius: 2px;
}

.table > tbody > tr:hover {
  background-color: #F1F3FA !important;
  cursor: pointer;
}

.table>:not(caption)>*>* {
  border: none;
}

tr td:first-of-type, th:first-of-type {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

tr td:last-of-type, th:last-of-type {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.form-switch .form-check-input {
  width: 2.3em;
  height: 1.3em;
}
.form-control, .form-select, .select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  /* line-height: 1.9 !important; */
  /* background-color: #f7f7f8 !important; */
  border-color: #aaa !important;
  height: 42px !important;
  border-radius: 16px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 7px !important;
}
textarea.form-control {
  min-height: calc(5.5em + .75rem + 2px) !important;
  resize: none !important;
}

.form-control:focus {
  border: 1px solid var(--wz-blue) !important ;
  box-shadow: none !important;
}

.white-form-control {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

a {
  color: var(--wz-blue) !important;
  text-decoration: none !important;
  font-weight: 600;
}

label {
  font-size: 14px;
  font-weight: 600 !important;
  margin-bottom: 0.5rem;
  color: var(--wz-typography) !important
}

h1, h2, h3, h4, h5, h6, p, span, ul, ol, li {
  color: var(--wz-form-text);
}

/* .form-group label {
  font-size: 14px;
  font-weight: 600 !important;
  margin-bottom: 0.5rem;
} */

.pointer {
  cursor: pointer;
}

.bold-text {
  font-weight: 600 !important;
}
.semi-bold-text {
  font-weight: 500 !important;
}

.breadcrumb-block {
  margin-bottom: 1.5rem !important;
}
.sidebar-icon svg {
  width: 24px !important;
  height: 24px !important;
  margin-top: -2px !important;
  margin-right: 15px !important;
}

/* .sidebar-icon {
  margin-top: -8px !important;
  margin-right: 15px !important;
} */

.sidebar-icon svg > path,
.sidebar-icon svg > g > path,
.sidebar-icon svg > g > g > path {
  fill: #8F92A1;
}

.btn svg > path,
.btn svg > g > path,
.btn svg > g > g > path {
  fill: #FFFFFF;
}

.sidebar-icon.active svg > path,
.sidebar-icon.active svg > g > path,
.sidebar-icon.active svg > g > g > path {
  fill: var(--wz-blue);
}

.dashboard-card svg {
  width: 44px !important;
  height: 44px !important;
}

.account-icon > svg > path {
  fill: var(--wz-blue);
}

.organization-icon > svg > path {
  fill: #00875a;
}

.trainings-icon > svg > path {
  stroke: #5243AA;
  fill: #5243AA;
}

.courses-icon > svg > path {
  fill: #FF991F;
}
.sidebar-icon svg > g {
  opacity: 1 !important;
}

.sidebar-item > .sidebar-link > span {
  color: #8F92A1;
}

.sidebar-item > .sidebar-link.active > span {
  color: var(--wz-blue);
}

.breadcrumb-text {
  font-size: 1.5rem;
  font-weight: 800;
  color: #000000;
}

.page-wrapper {
	background: #FAFAFB;
  position: relative;
  height: 90vh;
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
  padding: 1.25rem;
}

/*  X-Small devices (portrait phones, less than 576px)
 No media query for `xs` since this is the default in Bootstrap */

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .page-wrapper {
    background: #FAFAFB;
    position: relative;
    height: 90vh;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    padding: 1.25rem;
  }
}

/*  Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .page-wrapper {
    background: #FAFAFB;
    position: relative;
    height: 100vh;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    padding: 1.25rem;
  }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .page-wrapper {
    background: #FAFAFB;
    position: relative;
    height: 100vh;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    padding: 1.25rem;
  }
}

/*  X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .page-wrapper {
    background: #FAFAFB;
    position: relative;
    height: 100vh;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    padding: 1.25rem;
  }
}

/*  XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .page-wrapper {
    background: #FAFAFB;
    position: relative;
    height: 100vh;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    padding: 1.25rem;
  }
}

.page-wrapper>.container-fluid,
.page-wrapper>.container-lg,
.page-wrapper>.container-md,
.page-wrapper>.container-sm,
.page-wrapper>.container-xl,
.page-wrapper>.container-xxl {
	padding: 20px;
	min-height: calc(100vh - 180px)
}

/* #main-wrapper .content {
	margin-left: 250px;
} */

.it-card {
  width: 100%;
  height: 25rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
}
.it-card .it-card-heading {
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--wz-typography);
}
.it-card .it-card-body {

}
/* Scrollbar */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #D2F1DD;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4169E1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4169E1;
}
/********************************** begin of side-bar  **********************************/

.left-sidebar {
	position: absolute;
	width: 250px;
	height: 100%;
	top: 0px;
	z-index: 10;
	padding-top: 64px;
	background: #fff;
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in
}

#main-wrapper .left-sidebar,
#main-wrapper .left-sidebar ul {
	background: #233242
}

#main-wrapper .left-sidebar,
#main-wrapper .left-sidebar {
	background: #fff
}

#main-wrapper .left-sidebar .sidebar-nav ul,
#main-wrapper .left-sidebar .sidebar-nav ul {
	background: #fff
}

#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link,
#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link {
	color: #8F92A1;
	opacity: 1
}

#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link i,
#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link i {
	color: #8F92A1
}

#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link.active,
#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link:hover,
#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link.active,
#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link:hover {
	opacity: 1
}

#main-wrapper .left-sidebar .sidebar-nav ul .nav-small-cap,
#main-wrapper .left-sidebar .sidebar-nav ul .nav-small-cap {
	color: #2b2b2b
}

#main-wrapper .left-sidebar .sidebar-nav .has-arrow:after,
#main-wrapper .left-sidebar .sidebar-nav .has-arrow:after {
	border-color: #2b2b2b
}

.sidebar-nav ul .sidebar-item {
	width: 250px
}

.sidebar-nav ul .sidebar-item {
	width: 250px
}

.sidebar-nav ul .sidebar-item .sidebar-link {
	color: #000;
	padding: 12px 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	white-space: nowrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	line-height: 25px;
  text-decoration: none;
	/* opacity: 0.5 */
}

.sidebar-nav ul .sidebar-item .sidebar-link i {
	font-style: normal;
	width: 35px;
	line-height: 25px;
	font-size: 20px;
	color: #fff;
	display: inline-block;
	text-align: center
}

.sidebar-nav ul .sidebar-item .sidebar-link.active,
.sidebar-nav ul .sidebar-item .sidebar-link:hover {
	opacity: 1
}

.sidebar-nav ul .sidebar-item.selected>.sidebar-link {
	color: #fff
}

.sidebar-nav ul .sidebar-item.selected>.sidebar-link i {
	color: #fff
}

.sidebar-nav>#sidebarnav>.sidebar-item.selected>.sidebar-link,
.sidebar-nav>#sidebarnav>.sidebar-item>.sidebar-link:hover {
  border-left-color: var(--wz-blue);
  border-left-width: 4px;
}

.sidebar-nav>#sidebarnav>.sidebar-item>.sidebar-link {
  margin: 5px 0px;
	border-left: 2px solid transparent;
  padding: 15px 25px;
}

.sidebar-nav ul {
	margin: 0px;
	padding: 0px
}

.sidebar-nav ul li {
	list-style: none
}

.collapse.in {
	display: block
}

@media (min-width: 768px) {
  #main-wrapper .sidebar-nav .has-arrow:after,
	#main-wrapper .sidebar-nav .nav-small-cap {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center
	}
  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .page-wrapper {
      margin-left: 250px;
  }
}

@media (min-width:768px) and (max-width:991.98px) {
	#main-wrapper .sidebar-nav ul .sidebar-item {
		width: 65px
	}
}

@media (max-width:767px) {
	#main-wrapper {}
	#main-wrapper[data-sidebartype=mini-sidebar] .left-sidebar {
		left: -251px
	}
	#main-wrapper.show-sidebar .left-sidebar {
		left: 0
	}
	#main-wrapper .topbar .top-navbar .navbar-collapse {
		position: relative;
		top: 64px
	}
	#main-wrapper .topbar .top-navbar .navbar-collapse {
		position: relative;
		top: 64px
	}
}

@media (min-width:768px) and (max-width:991.98px) {
	#main-wrapper .sidebar-nav ul .sidebar-item {
		width: 65px
	}
}

/***************** end of side bar ********************/


/***************** begin of top bar ********************/

.topbar {
	position: relative;
	z-index: 50;
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	background: #fff;
	height: 64px
}

.topbar .top-navbar {
	min-height: 64px;
	padding: 0px
}
/*  X-Small devices (portrait phones, less than 576px)
 No media query for `xs` since this is the default in Bootstrap */

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .topbar {
    position: relative;
    z-index: 50;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    background: #fff;
    height: 64px
  }
  .topbar .top-navbar {
    min-height: 64px;
    padding: 0px
  }
}

/*  Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .topbar {
    position: relative;
    z-index: 50;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    background: #fff;
    height: 64px
  }
  .topbar .top-navbar {
    min-height: 64px;
    padding: 0px
  }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .topbar {
    position: relative;
    z-index: 5;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    background: #fff;
    height: 90px
  }
  .topbar .top-navbar {
    min-height: 90px;
    padding: 0px
  }
}

/*  X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .topbar {
    position: relative;
    z-index: 5;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    background: #fff;
    height: 90px
  }
  .topbar .top-navbar {
    min-height: 90px;
    padding: 0px
  }
}

/*  XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .topbar {
    position: relative;
    z-index: 5;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    background: #fff;
    height: 90px
  }
  .topbar .top-navbar {
    min-height: 90px;
    padding: 0px
  }
}
.topbar .navbar-collapse {
	padding: 0px 10px 0 0
}

.topbar .top-navbar .dropdown-toggle:after {
	display: none
}

.topbar .top-navbar .navbar-header {
	/* line-height: 64px */
}

.topbar .top-navbar .navbar-header .navbar-brand {
	width: 250px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0px;
	padding: 0 10px 0 10px;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
	margin-right: 5px
}

.topbar .top-navbar .navbar-header .navbar-brand .sidebartoggler {
	color: red
}

.topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
	padding: 0px 15px;
	font-size: 0.875rem;
	line-height: 64px;
	height: 64px
}

.topbar .top-navbar .navbar-nav>.nav-item>.nav-link .noti {
	position: absolute;
	top: 13px;
	left: 28px;
	font-size: 60%
}

.topbar .top-navbar .navbar-nav>.nav-item {
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in
}

.topbar .top-navbar .navbar-nav>.nav-item:hover {
	background: rgba(0, 0, 0, 0.03)
}

.topbar .mailbox,
.topbar .user-dd {
	min-width: 280px
}

.topbar .nav-toggler,
.topbar .topbartoggler {
	color: #fff;
	padding: 0 15px
}

@media (max-width:767px) {
	.topbar .top-navbar .navbar-collapse.collapsing,
	.topbar .top-navbar .navbar-collapse.show {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		border-top: 1px solid rgba(0, 0, 0, 0.1)
	}
	.topbar .top-navbar .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row
	}
	.topbar .top-navbar .navbar-nav .dropdown {
		position: static
	}
	.topbar .top-navbar .navbar-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between
	}
	.topbar .top-navbar .dropdown-menu {
		position: absolute;
		width: 100%
	}
}


.dark-logo, .light-logo {
  height: 1.8rem;
  width: auto;
}
#main-wrapper .topbar .top-navbar .navbar-header,
#main-wrapper .topbar .top-navbar .navbar-header {
	background: #233242
}

#main-wrapper .topbar .top-navbar .navbar-header,
#main-wrapper .topbar .top-navbar .navbar-header {
	background: #fff
}

#main-wrapper .topbar .top-navbar .navbar-header .navbar-brand .dark-logo,
#main-wrapper .topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
	display: inline
}

#main-wrapper .topbar .top-navbar .navbar-header .navbar-brand .light-logo,
#main-wrapper .topbar .top-navbar .navbar-header .navbar-brand .light-logo {
	display: none
}

#main-wrapper .topbar .top-navbar .navbar-header .nav-toggler,
#main-wrapper .topbar .top-navbar .navbar-header .sidebartoggler,
#main-wrapper .topbar .top-navbar .navbar-header .topbartoggler,
#main-wrapper .topbar .top-navbar .navbar-header .nav-toggler,
#main-wrapper .topbar .top-navbar .navbar-header .sidebartoggler,
#main-wrapper .topbar .top-navbar .navbar-header .topbartoggler {
	color: #6a7a8c
}

#main-wrapper .topbar .navbar-collapse,
#main-wrapper .topbar,
#main-wrapper .topbar .navbar-collapse,
#main-wrapper .topbar {
	background: #233242
}

#main-wrapper .topbar .navbar-collapse,
#main-wrapper .topbar,
#main-wrapper .topbar .navbar-collapse,
#main-wrapper .topbar {
	background: #fff
}

#main-wrapper .topbar .top-navbar .navbar-header {
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in
}

@media (min-width:768px) {
	#main-wrapper .topbar .top-navbar .navbar-header {
		width: 250px
	}
	#main-wrapper .topbar .top-navbar .navbar-collapse {
		margin-left: 250px
	}
}

/***************** end of top bar ********************/


/* Waves Effect */
.waves-effect {
	position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	vertical-align: middle;
	z-index: 1;
	will-change: opacity, transform;
	-webkit-transition: all 0.1s ease-out;
	-o-transition: all 0.1s ease-out;
	transition: all 0.1s ease-out
}

.waves-effect .waves-ripple {
	position: absolute;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	margin-left: -10px;
	opacity: 0;
	background: rgba(0, 0, 0, 0.2);
	-webkit-transition: all 0.7s ease-out;
	-o-transition: all 0.7s ease-out;
	transition: all 0.7s ease-out;
	-webkit-transition-property: -webkit-transform, opacity;
	-o-transition-property: -o-transform, opacity;
	-webkit-transition-property: opacity, -webkit-transform;
	transition-property: opacity, -webkit-transform;
	-o-transition-property: transform, opacity;
	transition-property: transform, opacity;
	transition-property: transform, opacity, -webkit-transform;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	pointer-events: none
}

.waves-effect.waves-light .waves-ripple {
	background-color: rgba(255, 255, 255, 0.45)
}

.waves-effect.waves-red .waves-ripple {
	background-color: rgba(244, 67, 54, 0.7)
}

.waves-effect.waves-yellow .waves-ripple {
	background-color: rgba(255, 235, 59, 0.7)
}

.waves-effect.waves-orange .waves-ripple {
	background-color: rgba(255, 152, 0, 0.7)
}

.waves-effect.waves-purple .waves-ripple {
	background-color: rgba(156, 39, 176, 0.7)
}

.waves-effect.waves-green .waves-ripple {
	background-color: rgba(76, 175, 80, 0.7)
}

.waves-effect.waves-teal .waves-ripple {
	background-color: rgba(0, 150, 136, 0.7)
}

html body .waves-notransition {
	-webkit-transition: none;
	-o-transition: none;
	transition: none
}

.waves-circle {
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	text-align: center;
	width: 2.5em;
	height: 2.5em;
	line-height: 2.5em;
	border-radius: 50%;
	-webkit-mask-image: none
}

.waves-input-wrapper {
	border-radius: 0.2em;
	vertical-align: bottom
}

.waves-input-wrapper .waves-button-input {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1
}

.waves-block {
	display: block
}


/* Datepicker css */

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active[disabled] {
  background-color: var(--wz-blue) !important;
}

.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
  background-color: var(--wz-blue) !important;
  background-image: linear-gradient(to bottom,var(--wz-blue),var(--wz-blue)) !important;
  background-repeat: repeat-x !important;
  border-color: var(--wz-blue) !important;
  color: #fff !important;
}

.datepicker td, .datepicker th {
  font-size: 0.75rem !important;
}


.page-identifier {
  background-color: #ffffff;
  border-radius: .625rem;
  height: 2.5rem;
  width: auto;
  margin-top: 1.25rem;
}

.page-identifier span {
  height: 2.5rem;
  display: inline-flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  border-radius: .625rem;
  color: #030229 !important;
  font-weight: bold;
  font-size: .75rem;
  cursor: pointer;

}

.page-identifier .active {
  color: #ffffff !important;
  background-color: var(--wz-blue);
}

.table {
  padding: 0 !important;
  width: 100% !important;
}

span > a.paginate_button.current {
  color: white !important;

}



.dataTables_empty {
  display: none;
}

.table.dataTable.no-footer {
  border-bottom: none;
}
.dataTables_info, .dataTables_length {
  margin-left: 0px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
}
.dataTables_wrapper .dataTables_paginate {
  /* margin-right: 0.4rem; */
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
}

.dataTables_filter {
  /* margin-right: 1.25rem; */
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
  border: var(--wz-blue) !important;
  background-color: var(--wz-blue) !important;
  border-radius: 15%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--wz-blue) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: none !important;
  background-color: #bbeacc !important;
  background: #bbeacc !important;
  border-radius: 15%;
  /* W3C */
}

/* Modal */
.modal-content {
  border-radius: 2rem !important;
}

.modal-header {
  padding: 2rem 1rem 1rem 1rem !important;
  border-bottom: none !important;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 500;
}


/* WYSIWYG */
.nw-editor {
  height: 12.5rem !important;
}
